<template>
  <div class="extra-modal">
    <template v-if="extra">
      <base-heading type="2">
        {{ extra.name }}
      </base-heading>

      <picture
        v-if="!sources.length && extra.image"
        class="extra-modal__image"
      >
        <img v-bind:src="`${extra.image}?w=900&q=80`" />
      </picture>

      <VideoPlayer
        v-if="sources.length"
        v-bind:src="sources"
        v-bind:placeholder="`${extra.image}?w=900&q=80`"
        v-bind:track-program="extra.program ? extra.program.theme : null"
        v-bind:track-title="extra.name"
        v-bind:track-period="currentPeriodNumber"
        v-bind:track-intensity="trackIntensity"
        v-bind:track-id="extra.id"
        track-goal="Extra"
      />

      <div
        v-else-if="extra.explanation"
        v-html-safe="extra.explanation"
        class="extra-modal__explanation"
      />

      <p
        v-html-safe="extra.description"
        class="extra-modal__description"
      />

      <a
          v-if="extra.url"
          v-bind:href="extra.url"
          target="_blank"
          rel="noopener noreferrer"
          class="button button--primary extra-modal__urlButton"
      >
        <base-button modifiers="primary">
          {{ extra.button_text || extra.name }}
        </base-button>
      </a>
      <base-button
        modifiers="secondary block fullWidth"
        v-on:click="$emit('close')"
      >
        {{ $t('programDetailExtra.closeButtonLabel') }}
      </base-button>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VideoPlayer from '@/components/VideoPlayer';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import { isIOS, isSafari } from '@/utils/device';

export default {
  components: {
    BaseHeading,
    BaseButton,
    VideoPlayer,
  },

  props: {
    extraId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      extra: null,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('period', ['currentPeriod', 'currentPeriodNumber']),
    ...mapGetters('workout', ['currentWorkout']),

    sources() {
      if (!this.extra.streams) {
        return [];
      }

      const streams = this.extra.streams.filter(({ file }) => !!file);

      if (streams.length > 1 && (isIOS() || isSafari())) {
        const hlsIndex = streams.findIndex(({ type }) => type === 'hls');

        if (hlsIndex > 0) {
          const hlsStream = streams[hlsIndex];

          streams.splice(hlsIndex, 1);
          streams.splice(0, 0, hlsStream);
        }
      }

      return streams;
    },

    trackIntensity() {
      const { active_intensity } = this.currentProgram;
      const { intensities } = this.currentPeriod;
      const intensity = intensities.find(({ intensity }) => intensity === active_intensity);

      return intensity ? intensity.name : '-';
    },
  },

  watch: {
    currentWorkout(newValue) {
      this.extra = newValue;
    },
  },

  mounted() {
    this.setExtra();
  },

  unmounted() {
    this.$store.commit('workout/unsetCurrentWorkout');
  },

  methods: {
    setExtra() {
      const extra = this.currentPeriod.extras.find(({ id }) => id === Number(this.extraId));

      if (extra) {
        if (extra.type === 'bonus') {
          this.$store.dispatch('workout/fetch', this.extraId);
          return;
        }

        this.extra = extra;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.extra-modal {
  .modal__window {
    @include desktop {
      height: auto;
    }
  }

  .button {
    margin-top: auto;

    + .button {
      margin-top: 1rem;
    }
  }

  .extra-modal__urlButton {
    display: block;
    margin-bottom: 2rem;

    button {
      width: 100%;
    }
  }

  .video-player {
    margin: 0 0 32px;
  }
}

.extra-modal__description {
  display: block;
  margin: 0 0 rem(16px) 0;
  text-align: center;
}

.extra-modal__description {
  margin-bottom: 32px;
  max-width: 100%;

  p {
    max-width: 100%;

    &:empty,
    &::content {
      display: none;
    }
  }
}

.extra-modal__explanation {
  margin-left: auto;
  margin-right: auto;
}

.extra-modal__image {
  position: relative;
  display: block;
  margin: 0 0 32px;
  padding: 56% 0 0;
  width: 100%;
  border-radius: $border-radius;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
}
</style>
